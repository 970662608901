@use 'color' as *;
@use 'grid' as *;
@use 'function' as *;

:root{
    @each $color, $types in $clrs {
        @each $type, $value in $types {
            --clr-#{$color}-#{$type}: #{$value};
        }

    }
    --bg-main: linear-gradient(to right bottom, var(--clr-bleu-6), var(--clr-bleu-4));
    --color-nav-link: #fff;
    --color-navlink-over: var(--clr-jaune-0);
    --bg-cadre: #fff;
    //--bg-header: linear-gradient( #{$color-orange-1}, #{$color-orange-0});
    --bg-header: linear-gradient( var(--clr-bleu-3), var(--clr-bleu-2));
    --bg-footer: linear-gradient( var(--clr-bleu-1), var(--clr-bleu-2));
    --border-header:  var(--clr-bleu-4);
    --color-orange-1: var(--clr-orange-1);
    --color-orange-0: var(--clr-orange-0);
    --color-violet-4:var(--clr-bleu-4);
    --bx-shadow-header: 0 0.1rem 0.2rem  var(--clr-jaune-0);
    --bx-shadow-footer: 0 -0.1rem 0.2rem  var(--clr-jaune-0);
    --clr-text: var(--clr-bleu-0);
    --clr-title: var(--clr-bleu-0);
    --clr-small-text: (rgba(0, 0, 0),50);
}

$heigth-footer: 4rem;
$heigth-nav: 6rem;
$logoT: calc( #{$heigth-nav} + 2rem);
$logoT1: calc( #{$heigth-nav} + 1rem);

$weight-link: 600;
$size-link: 1.3rem;
$size-text: 1.1rem;

$medium: rem(961);
$menu-anim: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
$t-duree: 200ms;

.danger {
    color: red !important;
}
    
.rounded {
    border-radius: rem(15);
}

 

@media screen  and (max-width:  cal($medium - rem(1))){
    html {
        font-size: 20px;
    }
};