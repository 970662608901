@use '../abstracts' as * ;

.admin {

    &-sejour {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(12);
        padding: rem(10);
        background-color: var(--clr-jaune-6);
        width: 100%;
        border: rem(1) solid var(--clr-bleu-1);

        &-card {
            display: flex;
            flex-direction: column;
            justify-content:space-around;
            flex-wrap: wrap;
            
            .badge{
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: rem(8);
                background-color: var(--clr-orange-0);
                color: var(--clr-jaune-6);
                border: rem(2) solid var(--clr-bleu-0);
                border-radius: rem(10);
            }

            .card-text {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content:space-around;
            }

            .badgeNb {
                background-color: green;
                color: var(--color-nav-link);
                border: rem(2) solid #000;
                padding: rem(4) rem(10);
                margin: 10px 0;
                font-weight: 700;
                border-radius:rem(10);

            }
        }

        .action {
            display: flex;
            flex-direction: row;
            justify-content:space-between;
        }  
    }

    &-page {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(12);
        padding: rem(10);
        background-color: var(--clr-jaune-6);
        width: 100%;
        border: rem(1) solid var(--clr-bleu-1);

        &-card {
            display: flex;
            flex-direction: row;
            justify-content:space-between;
            align-items: flex-start;
            flex-wrap: wrap;
           
            .badge {

              a {
                span {
                    display: inline-block;
                    margin-left: rem(4);
                }
              }
            }

            .card-text {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content:space-around;
            }

            .badgeNb {
               
                background-color: green;
                color: var(--color-nav-link);
                font-weight: 700;
                border-radius:rem(10);
                
                
            }
        }
    }

    &-galerie {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(12);
        padding: rem(10);
        background-color: var(--clr-jaune-6);
        width: 100%;
        border: rem(1) solid var(--clr-bleu-1);
        flex-wrap: wrap;

        &-vignette {
            display: block;
            position: relative;
            border: rem(1) solid blue;
            margin: 6px;
            padding: 0;
            border-radius: rem(15);
            
    
            .action {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content:space-between;
                
                bottom: 0;
                left: 0;
                width: 100%;

                &-small {
                    font-size: rem(13);
                }
            }
        }
    }    
}


@media screen and (min-width: $medium) {

    .admin {

        &-sejour {

            &-card {
                display: flex;
                flex-direction: row;
                justify-content:space-around;
                flex-wrap: wrap;
            }

            .action {
                justify-content:flex-start;

                a {
                    margin-right: rem(4);
                }
            }
        }

        &-page {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: rem(12);
            padding: rem(10);
            background-color: var(--clr-jaune-6);
            width: 100%;
            border: rem(1) solid var(--clr-bleu-1);
            

            .titre {
                margin-right: rem(6);
            }
    
            &-card {
                display: flex;
                flex-direction: row;
                justify-content:space-between;
                align-items: center;
                flex-wrap: wrap;
                flex: auto;
                
            }
        }    
    }       
}