@use '../abstracts' as * ;

.dropzone {
    display: flex;
    flex-direction: row;
    border: 2px solid var(--clr-bleu-1);
    color: var(--clr-bleu-4)
}

.dz-message {
    text-align: center;
    width: 100%;
}