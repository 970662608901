@use '../abstracts/' as *;

.btn {
    @include button();
}

.btn-danger {
    @include button(red);
}

