@use '../abstracts/' as *;
   
form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: rem(6);
    height: 100%;

    label {
        margin-bottom: rem(6);
        width: 100%;
    }

    input {
        margin-bottom: rem(6);
        padding: rem(3);
        width: 100%;
        border-radius: rem(10);

        border: 2px inset var(--clr-bleu-2);
        
        background-color: var(--clr-bleu-6);
        box-shadow: none;
        outline: none;

        &:focus, &:hover{

            border: 2px inset var(--clr-jaune-0) ;
        }
    }

    .form-error{
        margin-bottom: rem(6);
        padding: rem(6) rem(0);
        background-color: beige;
        color: var(--clr-violet-1);
    }
}

