@use '../abstracts/' as *;

.accueil {
    display: block;
    
}
.main-photo {
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}


@media screen and (min-width: $medium) {

    .accueil {
       
        p {
            font-size: 1.4rem;
            width: 80%;
        }
    }
}