/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html, body {
  height: 100vh;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.3;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  --clr-orange-0: #FB900E;
  --clr-orange-1: #FCA030;
  --clr-orange-2: #FCB053;
  --clr-orange-3: #FDC075;
  --clr-orange-4: #FDD098;
  --clr-orange-5: #FEDFBA;
  --clr-orange-6: #FEEFDD;
  --clr-violet-0: #5F1C57;
  --clr-violet-1: #8A287E;
  --clr-violet-2: #B534A6;
  --clr-violet-3: #CD53BF;
  --clr-violet-4: #DA7ECF;
  --clr-violet-5: #E6A9DF;
  --clr-violet-6: #F3D4EF;
  --clr-bleu-0: #030B3E;
  --clr-bleu-1: #06167C;
  --clr-bleu-2: #0920B8;
  --clr-bleu-3: #0D2CF4;
  --clr-bleu-4: #4960F6;
  --clr-bleu-5: #8695F9;
  --clr-bleu-6: #C2CAFC;
  --clr-jaune-0: #FFFF00;
  --clr-jaune-1: #FFFF24;
  --clr-jaune-2: #FFFF49;
  --clr-jaune-3: #FFFF6D;
  --clr-jaune-4: #FFFF92;
  --clr-jaune-5: #FFFFB6;
  --clr-jaune-6: #FFFFDB;
  --bg-main: linear-gradient(to right bottom, var(--clr-bleu-6), var(--clr-bleu-4));
  --color-nav-link: #fff;
  --color-navlink-over: var(--clr-jaune-0);
  --bg-cadre: #fff;
  --bg-header: linear-gradient( var(--clr-bleu-3), var(--clr-bleu-2));
  --bg-footer: linear-gradient( var(--clr-bleu-1), var(--clr-bleu-2));
  --border-header: var(--clr-bleu-4);
  --color-orange-1: var(--clr-orange-1);
  --color-orange-0: var(--clr-orange-0);
  --color-violet-4:var(--clr-bleu-4);
  --bx-shadow-header: 0 0.1rem 0.2rem var(--clr-jaune-0);
  --bx-shadow-footer: 0 -0.1rem 0.2rem var(--clr-jaune-0);
  --clr-text: var(--clr-bleu-0);
  --clr-title: var(--clr-bleu-0);
  --clr-small-text: (rgba(0, 0, 0),50);
}

.danger {
  color: red !important;
}

.rounded {
  border-radius: 0.9375rem;
}

@media screen and (max-width: cal(60rem)) {
  html {
    font-size: 20px;
  }
}
h1 {
  font-size: clamp(3rem, 5vw, 4rem);
  line-height: 3rem;
  font-weight: 700;
  color: var(--clr-title);
  padding-bottom: 0.5em;
}
h1 small {
  display: inline-block;
  font-size: 0.4em !important;
  font-weight: 500;
}

h2 {
  font-size: 2.2rem;
  padding-bottom: 0.5em;
  line-height: 2.2rem;
  color: var(--clr-title);
}

h3 {
  font-size: 1.6rem;
  padding-bottom: 0.5em;
  line-height: 1.6;
  color: var(--clr-title);
}

body {
  line-height: 1.4rem;
  margin-bottom: 1.5rem;
  color: var(--clr-title);
  font-size: 1.4rem;
}

p {
  margin: 1rem 0;
}

b {
  color: var(--clr-bleu-0);
  font-weight: 700;
}

small {
  display: block;
  font-size: 0.9em !important;
  opacity: 0.6;
}

.info {
  font-size: 1.4rem;
}
.info small {
  display: inline-block;
  font-size: 0.7em !important;
  line-height: 1.4em;
  color: var(--clr-title);
  opacity: 0.6;
  padding: 0.125rem 0.25rem;
  border: 0.0625rem solid red;
  border-radius: 0.5rem;
}

.btn {
  display: inline-block;
  background-color: #0D2CF4;
  margin: 0.6em 0;
  padding: 0.5em 1em;
  border: 0.1em solid #051163;
  border-radius: 0.4em;
  text-decoration: none;
  color: white;
  box-shadow: 0 0 0;
  transition: all 200ms ease-out;
}
.btn:hover {
  background-color: #071a94;
  color: var(--clr-jaune-0);
  box-shadow: 0.1875rem, 0.1875rem, 0.125rem, black;
}

.btn-danger {
  display: inline-block;
  background-color: red;
  margin: 0.6em 0;
  padding: 0.5em 1em;
  border: 0.1em solid #660000;
  border-radius: 0.4em;
  text-decoration: none;
  color: white;
  box-shadow: 0 0 0;
  transition: all 200ms ease-out;
}
.btn-danger:hover {
  background-color: #990000;
  color: var(--clr-jaune-0);
  box-shadow: 0.1875rem, 0.1875rem, 0.125rem, black;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.375rem;
  height: 100%;
}
form label {
  margin-bottom: 0.375rem;
  width: 100%;
}
form input {
  margin-bottom: 0.375rem;
  padding: 0.1875rem;
  width: 100%;
  border-radius: 0.625rem;
  border: 2px inset var(--clr-bleu-2);
  background-color: var(--clr-bleu-6);
  box-shadow: none;
  outline: none;
}
form input:focus, form input:hover {
  border: 2px inset var(--clr-jaune-0);
}
form .form-error {
  margin-bottom: 0.375rem;
  padding: 0.375rem 0rem;
  background-color: beige;
  color: var(--clr-violet-1);
}

header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1rem 0 1rem;
  height: 6rem;
  width: 100%;
  background: var(--bg-header);
  box-shadow: var(--bx-shadow-header);
}

.nav-primary {
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /**
  * logo
  */
  /*
  * Nav list
  */
  /**
  * toggler
  */
}
.nav-primary .logo {
  align-self: baseline;
  width: calc(6rem + 2rem);
  height: calc(6rem + 2rem);
  display: flex;
  margin: 0 1rem;
  border-radius: 4rem;
  background-color: var(--clr-bleu-2);
  box-shadow: var(--bx-shadow-header);
}
.nav-primary .logo::before {
  content: "";
  position: absolute;
  background: var(--bg-header);
  top: 0rem;
  left: 1.5rem;
  width: 9rem;
  height: 6rem;
  z-index: 50;
}
.nav-primary .logo-link {
  position: relative;
  top: 0;
  left: 0.2rem;
  width: calc(6rem + 2rem);
  height: calc(6rem + 2rem);
  border-radius: 4rem;
  z-index: 100;
}
.nav-primary .logo-link::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.33rem;
  width: calc(6rem + 1rem);
  height: calc(6rem + 1rem);
  border-radius: 4rem;
  z-index: 100;
}
.nav-primary .logo-pieuvre {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../images/edm-logo-1.png");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 200;
}
.nav-primary .logo-pieuvre:hover {
  position: absolute;
  background-image: url("../../images/edm-logo-2.png");
}
.nav-primary .nav-list {
  display: none;
  position: absolute;
  flex-direction: column;
  top: 6rem;
  left: 0;
  width: 100vw;
  background-color: var(--clr-bleu-4);
  box-shadow: var(--bx-shadow-header);
  align-items: center;
  color: var(--color-nav-link);
  cursor: pointer;
}
.nav-primary .nav-link {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  text-decoration: none;
  color: #fff;
}
.nav-primary .nav-item {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0.375rem 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.3rem;
  /*
   &::before {
       content: '';
       position:absolute;
       width: 0%;
       bottom: 0;
       left: 50%;
       height: rem(4);
       background-color: var(--color-navlink-over);
       transition: $t-duree $menu-anim;
   }
   &:hover {
       &::before {
           content: '';
           position: absolute;
           bottom: 0;
           left: 0;
           width: 100%;   
       }
   }
   */
}
.nav-primary .nav-item[aria-current=page] {
  /*   &::before{           
         content: '';
         position:absolute;
         width: 100%;
         bottom: 0;
         left: 0;
         height: rem(4);
         background-color: var(--color-navlink-over);
     }
    */
}
.nav-primary .nav-item[aria-current=page] .nav-link {
  color: var(--color-navlink-over);
}
.nav-primary .nav-item:hover,
.nav-primary .nav-item:focus,
.nav-primary .nav-link:hover,
.nav-primary .nav-link:focus {
  color: var(--color-navlink-over);
  background-color: var(--clr-bleu-3);
}
.nav-primary .dropdown-menu {
  display: none;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  background-color: var(--clr-bleu-3);
}
.nav-primary .dropdown-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.nav-primary .dropdown {
  display: none;
}
.nav-primary .dropdown:checked ~ .dropdown-menu {
  display: flex;
}
.nav-primary .dropdown:checked ~ .dropdown-toggler .fa {
  transform: rotate(-90deg);
}
.nav-primary i {
  margin-left: 0.25rem;
  overflow: hidden;
  vertical-align: middle;
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.nav-primary .toggler {
  display: flex;
  align-content: center;
  width: 1.875rem;
  height: 1.25rem;
  background-color: transparent;
  cursor: pointer;
  border: none;
  transition: 1s;
}
.nav-primary .toggler:hover .burg {
  background-color: var(--clr-jaune-3);
}
.nav-primary .toggler:hover .burg::before, .nav-primary .toggler:hover .burg::after {
  background-color: var(--clr-jaune-3);
}
.nav-primary .drop {
  display: none;
}
.nav-primary .burg {
  display: block;
  position: relative;
  width: 100%;
  height: 0.25rem;
  background-color: var(--color-nav-link);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.nav-primary .burg::before, .nav-primary .burg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background-color: var(--color-nav-link);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.nav-primary .burg::before {
  top: 8px;
  left: 0;
}
.nav-primary .burg::after {
  top: 16px;
  left: 0;
}
.nav-primary .drop:checked ~ .nav-list {
  display: flex;
}
.nav-primary .drop:checked ~ #main-toggle .burg {
  background-color: transparent;
}
.nav-primary .drop:checked ~ #main-toggle .burg::before {
  top: 0;
  transform: translateY(0.5rem) rotate(-45deg);
}
.nav-primary .drop:checked ~ #main-toggle .burg::after {
  transform: translateY(-0.5rem) rotate(45deg);
}

@media screen and (min-width: 60.0625rem) {
  .nav-primary .toggler {
    display: none;
  }
  .nav-primary .dropdown:checked ~ .dropdown-menu {
    display: none;
  }
  .nav-primary .dropdown:checked ~ .dropdown-toggler .fa {
    transform: none;
  }
  .nav-primary .nav-list {
    display: flex;
    flex-direction: row;
    position: unset;
    background-color: transparent;
    box-shadow: none;
  }
  .nav-primary .dropdown-menu {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    justify-items: center;
    top: 100%;
    width: 15.625rem;
    padding: 0.5rem auto;
    background-color: var(--clr-bleu-2);
    border-radius: 0 0 1.25rem 1.25rem;
    transition: 200ms ease-in;
    transform: scaleY(0%);
    transform-origin: 0 0;
  }
  .nav-primary .dropdown-menu .nav-item {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    border: 0;
  }
  .nav-primary .dropdown-menu .nav-link {
    width: 100%;
    padding: 0.9375rem 0rem;
  }
  .nav-primary .dropdown-menu .nav-link:hover {
    color: var(--color-navlink-over);
    background-color: var(--clr-bleu-4);
  }
  .nav-primary .nav-item {
    position: relative;
    width: auto;
    margin-left: 0.625rem;
    font-size: clamp(1.05rem, 1.7vw, 10rem);
  }
  .nav-primary .nav-item:hover {
    background-color: transparent;
  }
  .nav-primary .nav-item:hover:last-child {
    border-radius: 0 0 1.25rem 1.25rem;
    overflow: hidden;
  }
  .nav-primary .nav-item:hover .dropdown-menu {
    display: flex;
    visibility: visible;
    transform: scaleY(100%);
    transform-origin: 0 0;
  }
  .nav-primary .nav-item:hover .dropdown-toggler .fa {
    transform: rotate(-90deg);
  }
  .nav-primary .nav-item .nav-link:hover {
    background-color: transparent;
  }
  .nav-primary .connect {
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
  .nav-primary .connect::before {
    display: none;
  }
  .nav-primary .connect i {
    margin-right: 0.5rem;
  }
}
.nav-sejour {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.nav-sejour .nav-list {
  display: flex;
  flex-direction: row;
}

main {
  display: flex;
  padding-top: calc(6rem + 2rem + 0.2rem);
  min-height: calc(100% - 4rem);
  padding-bottom: 2rem;
  background: var(--bg-main);
}

.cadre {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: var(--bg-cadre);
  padding: 1rem;
  margin-bottom: 2rem;
  gap: 2rem;
}
.cadre header {
  display: flex;
  position: unset;
  background: none;
  padding: 0;
  box-shadow: none;
  height: unset;
}

section {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 60.0625rem) {
  .cadre {
    width: min(98%, 75rem);
    border-radius: 0.625rem;
  }
}
footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  background: var(--bg-footer);
  box-shadow: var(--bx-shadow-footer);
}

@media only screen and (max-width: 60.0625rem) {
  footer nav {
    display: flex;
    flex-direction: column;
  }
}
.dropzone {
  display: flex;
  flex-direction: row;
  border: 2px solid var(--clr-bleu-1);
  color: var(--clr-bleu-4);
}

.dz-message {
  text-align: center;
  width: 100%;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.log-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 18.75rem;
  border-radius: 0.9375rem;
  overflow: hidden;
}

@media screen and (min-width: 60.0625rem) {
  .login {
    flex-direction: row;
    gap: 1.25rem;
  }
  .log-photo {
    width: 50vw;
    height: 100%;
  }
}
.admin-sejour {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  padding: 0.625rem;
  background-color: var(--clr-jaune-6);
  width: 100%;
  border: 0.0625rem solid var(--clr-bleu-1);
}
.admin-sejour-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
}
.admin-sejour-card .badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--clr-orange-0);
  color: var(--clr-jaune-6);
  border: 0.125rem solid var(--clr-bleu-0);
  border-radius: 0.625rem;
}
.admin-sejour-card .card-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.admin-sejour-card .badgeNb {
  background-color: green;
  color: var(--color-nav-link);
  border: 0.125rem solid #000;
  padding: 0.25rem 0.625rem;
  margin: 10px 0;
  font-weight: 700;
  border-radius: 0.625rem;
}
.admin-sejour .action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.admin-page {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  padding: 0.625rem;
  background-color: var(--clr-jaune-6);
  width: 100%;
  border: 0.0625rem solid var(--clr-bleu-1);
}
.admin-page-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.admin-page-card .badge a span {
  display: inline-block;
  margin-left: 0.25rem;
}
.admin-page-card .card-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.admin-page-card .badgeNb {
  background-color: green;
  color: var(--color-nav-link);
  font-weight: 700;
  border-radius: 0.625rem;
}
.admin-galerie {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.625rem;
  background-color: var(--clr-jaune-6);
  width: 100%;
  border: 0.0625rem solid var(--clr-bleu-1);
  flex-wrap: wrap;
}
.admin-galerie-vignette {
  display: block;
  position: relative;
  border: 0.0625rem solid blue;
  margin: 6px;
  padding: 0;
  border-radius: 0.9375rem;
}
.admin-galerie-vignette .action {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
}
.admin-galerie-vignette .action-small {
  font-size: 0.8125rem;
}

@media screen and (min-width: 60.0625rem) {
  .admin-sejour-card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .admin-sejour .action {
    justify-content: flex-start;
  }
  .admin-sejour .action a {
    margin-right: 0.25rem;
  }
  .admin-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.75rem;
    padding: 0.625rem;
    background-color: var(--clr-jaune-6);
    width: 100%;
    border: 0.0625rem solid var(--clr-bleu-1);
  }
  .admin-page .titre {
    margin-right: 0.375rem;
  }
  .admin-page-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex: auto;
  }
}
.accueil {
  display: block;
}

.main-photo {
  width: 100%;
}
.main-photo img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 60.0625rem) {
  .accueil p {
    font-size: 1.4rem;
    width: 80%;
  }
}
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.11.1
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  -webkit-transition: left 0.4s ease, -webkit-transform 0.4s ease;
  transition: left 0.4s ease, -webkit-transform 0.4s ease;
  transition: left 0.4s ease, transform 0.4s ease;
  transition: left 0.4s ease, transform 0.4s ease, -webkit-transform 0.4s ease, -moz-transform 0.4s ease;
}

#baguetteBox-slider.bounce-from-right {
  -webkit-animation: bounceFromRight 0.4s ease-out;
  animation: bounceFromRight 0.4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  -webkit-animation: bounceFromLeft 0.4s ease-out;
  animation: bounceFromLeft 0.4s ease-out;
}

@-webkit-keyframes bounceFromRight {
  0%, 100% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
}
@keyframes bounceFromRight {
  0%, 100% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
}
@-webkit-keyframes bounceFromLeft {
  0%, 100% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
}
@keyframes bounceFromLeft {
  0%, 100% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
}
.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  -moz-border-radius: 15%;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1, .baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}