
@use '../abstracts' as * ;

.nav-primary {
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    /**
    * logo
    */
    .logo {
        align-self: baseline;
        width: $logoT;
        height: $logoT;
        display: flex;
        margin: 0 rem(16);
        border-radius: 4rem;
        background-color:var(--clr-bleu-2);
        box-shadow: var(--bx-shadow-header);

        &::before {
            content: '';
                position: absolute;
                background: var(--bg-header);
                top: 0rem;
                left: 1.5rem;
                width: 9rem;
                height: 6rem;     
                z-index: 50;
        }

        &-link {
            position: relative;
            top: 0;
            left: 0.2rem;
            width: $logoT;
            height: $logoT;
            border-radius: 4rem;
            z-index: 100; 

            &::before {
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0.33rem;
                width: $logoT1;
                height: $logoT1;
                border-radius: 4rem;
                z-index: 100;
            }
        }

        &-pieuvre {
            position: absolute;
            top : 0;
            left: 0;
            background-image: url('../../images/edm-logo-1.png');
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            z-index: 200;

            &:hover {
                position: absolute;
                background-image: url('../../images/edm-logo-2.png');
            }      
        }
    }
    /*
    * Nav list
    */
    .nav-list {
        display: none;
        position:absolute;
        flex-direction: column;
        top: $heigth-nav;
        left: 0;
        width: 100vw;
        background-color: var(--clr-bleu-4);
        box-shadow: var(--bx-shadow-header);  
        align-items: center;
        color: var(--color-nav-link);
        cursor: pointer;
    }

    .nav-link {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        text-decoration: none;
        color: #fff;
     }

    .nav-item {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: rem(6) 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: $size-link;
       /*
        &::before {
            content: '';
            position:absolute;
            width: 0%;
            bottom: 0;
            left: 50%;
            height: rem(4);
            background-color: var(--color-navlink-over);
            transition: $t-duree $menu-anim;
        }
        &:hover {
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;   
            }
        }
        */
        &[aria-current="page"]{
         /*   &::before{           
                content: '';
                position:absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                height: rem(4);
                background-color: var(--color-navlink-over);
            }
           */ 
            .nav-link {
                color: var(--color-navlink-over);
            }

        }
    }

    .nav-item:hover,
    .nav-item:focus,
    .nav-link:hover,
    .nav-link:focus {
        color: var(--color-navlink-over);
        background-color:  var(--clr-bleu-3);
    }

    .dropdown-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        justify-items: center;
        background-color:  var(--clr-bleu-3);

    }

    .dropdown-toggler {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
    }

    .dropdown {
        display: none;
    }

    .dropdown:checked {
        &~ .dropdown-menu {
            display: flex;
        }

        &~ .dropdown-toggler .fa {
            transform: rotate(-90deg);
            
        }
    }
    i {
       // width: rem(10);
        margin-left: rem(4);
        overflow: hidden;
        vertical-align:middle;
        transition: $t-duree $menu-anim;
    }

    /**
    * toggler
    */
    .toggler {
        display: flex;
        align-content: center;
        width: rem(30);
        height: rem(20);
        background-color: transparent;
        cursor: pointer;
        border: none;
        transition: 1s;

        &:hover {
            .burg {
                background-color: var(--clr-jaune-3);
                &::before,
                &::after {
                    background-color: var(--clr-jaune-3);
                }
            }
        }
    }

    .drop{
        display: none;
        
    }

    .burg {
        display: block;
        position: relative;
        width: 100%;
        height: rem(4);
        background-color: var(--color-nav-link);
        transition: $t-duree cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: rem(4);
            background-color: var(--color-nav-link);
            transition: $t-duree cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &::before {
            top: 8px;
            left: 0;
        }

        &::after {
            top: 16px;
            left: 0;
        }

        
    }

    .drop:checked { 
        &~ .nav-list {
            display: flex;
        }

        &~ #main-toggle .burg {
            background-color: transparent;
            
        }

        &~ #main-toggle .burg::before {
            top: 0;
            transform: translateY(rem(8)) rotate(-45deg);
        }

        &~ #main-toggle .burg::after {
            transform:  translateY(rem(-8)) rotate(45deg);
        }
    }

}

@media screen and (min-width: $medium) {
    .nav-primary {

        .toggler {
            display: none;   
        }

        .dropdown:checked {
            &~ .dropdown-menu {
                display: none;
            }

            &~ .dropdown-toggler .fa {
                transform: none;  
            }
        }

        .nav-list {
            display: flex;
            flex-direction: row;
            position: unset;
            background-color: transparent;
            box-shadow: none;
            
        }

        .dropdown-menu {
            display: flex;
            visibility: hidden;
            flex-direction: column;
            position: absolute;
            justify-items: center;
            top: 100%;
            width: rem(250);
            padding: rem(8) auto;
            background-color: var(--clr-bleu-2);
            border-radius: 0 0 rem(20) rem(20);
            transition: $t-duree ease-in; //cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: scaleY(0%);
            transform-origin: 0 0;
            
            .nav-item {
                margin: 0;
                display: flex;
                align-items: center;
                position: relative;
                width:auto;           
                border: 0;

                
            }

            .nav-link {
                width: 100%;
                padding: rem(15) rem(0);

                &:hover {
                    color: var(--color-navlink-over);
                    background-color: var(--clr-bleu-4);

                }
            }

        }

        .nav-item {
            position: relative;
            width: auto;
            margin-left: rem(10);
            font-size: clamp(1.05rem ,1.7vw, 10rem);

            &:hover {
                background-color: transparent;
               &:last-child{
                   border-radius: 0 0 rem(20) rem(20);
                overflow: hidden;
               }
                
                .dropdown-menu {
                    display: flex;
                    visibility: visible;
                    transform: scaleY(100%);
                    transform-origin: 0 0;
                    
                }
                .dropdown-toggler .fa {
                    transform: rotate(-90deg);
                    
                }
            }

            .nav-link {
 
                &:hover {
                    background-color: transparent;
                }
            }
        
        }

        .connect {
            margin-left: auto;
            display: flex;
            flex-direction: column;

            &::before {
                display:none;
            }

            i {
                margin-right:rem(8);    
            }
        }
    } 
}

.nav-sejour {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .nav-list {
        display: flex;
        flex-direction: row;
    }
}
