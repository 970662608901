@use '../abstracts' as * ;

.login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.log-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: rem(300);
    border-radius: rem(15);
    overflow: hidden;
}

@media screen and (min-width: $medium) {

    .login {
        flex-direction: row;
        gap: rem(20);
    }


    .log-photo {
        width: 50vw;
        height: 100%;
    }

}