
@use '../abstracts' as * ;

main {
    display: flex;
    padding-top: calc($logoT + 0.2rem);
    min-height: calc(100% - $heigth-footer ) ;
    padding-bottom: 2rem;
    background: var(--bg-main);
}

.cadre {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: var(--bg-cadre);
    padding: 1rem;
    margin-bottom: 2rem;
    gap: 2rem;

    header {
        display: flex;
        position: unset;
        background: none;
        padding: 0;
        box-shadow: none;
        height: unset;
    } 
}

section {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: $medium) {
    .cadre {
        width: min(98%, rem(1200));
        border-radius: rem(10);
    }
}
