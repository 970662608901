@use '../abstracts/' as *;

h1 {

    font-size: clamp(3rem, 5vw, 4rem);
    line-height: 3rem;
    font-weight: 700;
    color: var(--clr-title);
    padding-bottom: .5em;

    small {
        display: inline-block;
        font-size: 0.4em !important;
        font-weight: 500;
    }
}

h2 {
    font-size: 2.2rem;
    padding-bottom: .5em;
    line-height: 2.2rem;
    color: var(--clr-title);
}

h3 {
    font-size: 1.6rem;
    padding-bottom: .5em;
    line-height: 1.6;
    color: var(--clr-title);
}

body {
    line-height: 1.4rem;
    margin-bottom: 1.5rem;
    color: var(--clr-title);
    font-size: 1.4rem;
}

p {
    margin: 1rem 0;
}

b {
    color: var(--clr-bleu-0);
    font-weight: 700;  
}

small {
    display: block;
    font-size: 0.9em !important;
    opacity: 0.6;
}

.info {
    font-size: 1.4rem;
   
    
    small {
        display: inline-block;
        font-size: 0.7em !important;
        line-height: 1.4em;
        color:var(--clr-title);
        opacity: 0.6;
        padding: rem(2) rem(4);
        border: rem(1) solid red;
        border-radius: rem(8);
    }
}