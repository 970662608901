@use '../abstracts' as * ;

header {
    position: absolute;
    top:0;
    left:0;
    padding: 0 1rem 0 1rem;
    height: $heigth-nav;
    width: 100%;
    background: var(--bg-header);
    box-shadow: var(--bx-shadow-header);
}

   