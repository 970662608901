@use 'variables' as *;
@use 'color' as *;
@use 'function' as *;

@mixin button($bgcolor: map-get(map-get($clrs, bleu), 3)) {
    display: inline-block;
    background-color: $bgcolor;
    margin: 0.6em 0;
    padding: 0.5em 1em;
    border: 0.1em solid darken($bgcolor, 30%);
    border-radius: 0.4em;
    text-decoration: none;
    color: lighten($bgcolor, 70);
    box-shadow: 0 0 0 ;
    transition: all $t-duree ease-out;

    &:hover {
        background-color: darken($bgcolor, 20%);
        color: var(--clr-jaune-0);
        box-shadow: rem(3), rem(3), rem(2), darken($bgcolor, 60);
        
    }
}
