@use '../abstracts' as * ;

footer {
   display: flex;
   flex-direction: row;
   justify-content:space-between;
   height: 4rem;
   background: var(--bg-footer);
   box-shadow: var(--bx-shadow-footer);
}

@media only screen and (max-width: $medium) {
   footer {
      nav {
         display: flex;
         flex-direction: column;
      }
   }
}